import { ListenOptions } from "net";
import React from "react";

export interface JobOfferListProps {
  icon: "tasks" | "requirements" | "benefits";
  heading: string;
  items: any;
  additionalNote?: string;
}

export default function JobOfferList({
  icon,
  heading,
  items,
  additionalNote,
}: JobOfferListProps) {
  const iconCode =
    icon === "tasks" ? (
      <span>&#128195;</span>
    ) : icon === "requirements" ? (
      <span>&#128187;</span>
    ) : (
      <span>&#x1F4C8;</span>
    );
  return (
    <section className="sm:w-10/12 max-w-2xl mx-auto">
      <h2 className="text-center mb-4">
        {iconCode} {heading}
      </h2>
      <ul className="flex flex-col gap-2">
        {items.map((item: string) => (
          <li key={item} className="flex items-start gap-2">
            <span>&#x1F449;</span>
            <span>{item}</span>
          </li>
        ))}
      </ul>
      {additionalNote && (
        <p className="flex items-start gap-2 mt-4">
          <span className="opacity-0">&#x1F449;</span> {additionalNote}
        </p>
      )}
    </section>
  );
}
