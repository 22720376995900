import { graphql } from "gatsby";
import React from "react";
import ButtonGradient from "../components/ButtonGradient";
import JobOfferList from "../components/JobOfferList";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export interface JobOffer {
  data: any;
}

export default function JobOffer({ data }: JobOffer) {
  console.log(data.graphCmsJobOffer.data);

  const {
    heading,
    requirementsAdditionalNote,
    footerHeading,
    title,
    subheading,
    redirect,
    footerNote,
  } = data.graphCmsJobOffer;
  const { tasks, requirements, benefits } = data.graphCmsJobOffer.data;
  const seo = data.graphCmsJobOffer.seo;

  return (
    <>
      <SEO
        title={seo?.title || title}
        description={seo?.description}
        key={seo?.keywords}
      />
      <Layout>
        <article className="flex flex-col gap-14 lg:gap-28">
          <header className="mb-7 max-w-4xl text-center mx-auto">
            <h1>{heading}</h1>
            <p dangerouslySetInnerHTML={{ __html: subheading.html }}></p>
          </header>

          {/* tasks */}
          <JobOfferList icon="tasks" heading="Zadania:" items={tasks} />
          {/* requirements */}
          <JobOfferList
            icon="requirements"
            heading="Czego Ci potrzeba:"
            items={requirements}
            additionalNote={requirementsAdditionalNote}
          />
          {/* benefits */}
          <JobOfferList
            icon="benefits"
            heading="Co zyskujesz?"
            items={benefits}
          />

          <ButtonGradient
            link={redirect}
            text="Aplikuj"
            aClasses="self-center"
          ></ButtonGradient>

          <footer className="self-center">
            {footerHeading && <h3 className="text-center">{footerHeading}</h3>}
            <p className="text-justify">{footerNote}</p>
          </footer>
        </article>
      </Layout>
    </>
  );
}

export const query = graphql`
  query JobOffer($slug: String) {
    graphCmsJobOffer(slug: { eq: $slug }) {
      data
      requirementsAdditionalNote
      heading
      footerHeading
      title
      subheading {
        html
      }
      redirect
      footerNote
      seo {
        title
        keywords
        description
      }
    }
  }
`;
